<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

<style lang="scss" scoped>
.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  ::v-deep .text-field {
    border-bottom: 0.5px solid #f6f6f6;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

</style>
