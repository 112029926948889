<template>
  <div class="text-field">
    <img :src="icon" class="icon">
    <input class="field" :type="secureTextEntry ? 'password': 'text'" :value="value" @input="$emit('input', $event.target.value)" :placeholder="placeholder">
    <slot name="accessory" class="accessory"></slot>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    value: String,
    icon: String,
    placeholder: String,
    secureTextEntry: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.text-field {
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0 25px;

  .field {
    background: none;
    outline: none;
    border: none;
    flex-grow: 1;
    margin-left: 20px;
  }

  .icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
  }
}
</style>
