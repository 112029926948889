<template>
  <div class="forget-2">
    <h1>{{ $t.forgetInterpret.resetPasswordStep2 }}</h1>
    <card class="card">
      <text-field
        v-model="form.authCode"
        :icon="require('@/assets/img/auth/icon_login_password.png')"
        :placeholder="$t.forgetInterpret.placeholderEmailCode"
      >
        <template v-slot:accessory>
          <div :class="sendAuthCodeClasses" @click="actionSendAuthCode">
            {{ sendAuthCodeTitle }}
          </div>
        </template>
      </text-field>
    </card>
    <div class="btns">
      <div class="btn primary" @click="actionSubmit">
        <span>{{ $t.forgetInterpret.next }}</span>
      </div>
    </div>
    <p class="bottom-action" @click="actionRegister">
      <span>{{ $t.forgetInterpret.noAccountGoRegister }}</span>
    </p>
  </div>
</template>

<script>
import Card from "./components/Card";
import TextField from "./components/TextField";
import Schema from "async-validator";
export default {
  name: "ResetPasswordStep2",
  components: {
    Card,
    TextField,
  },
  data() {
    return {
      form: {
        email: "",
        authCode: "",
      },
      sendAuthCodeTime: 60,
      timer: null,
    };
  },
  computed: {
    sendAuthCodeTitle() {
      if (this.sendAuthCodeTime === 60) {
        return this.$t.forgetInterpret.sendAuthCode;
      } else {
        return this.sendAuthCodeTime + "";
      }
    },
    sendAuthCodeClasses() {
      const isActive = this.sendAuthCodeTime === 60;
      return {
        "send-auth-code-btn": true,
        active: isActive,
      };
    },
  },
  created() {
    this.form.email = this.$route.query.email || "";
  },
  methods: {
    actionSubmit() {
      const rule = {
        email: { required: true, message: this.$t.errorParamGoBack },
        authCode: {
          required: true,
          message: this.$t.forgetInterpret.placeholderEmailCode,
        },
      };
      const validator = new Schema(rule);
      validator
        .validate(this.form)
        .then((_) => {
          this.$commonMethod.showLoading();
          return this.$api.system.checkAuthCode({
            Email: this.form.email,
            AuthCode: this.form.authCode,
          });
        })
        .then((res) => {
          this.$commonMethod.hideLoading();

          this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
            isParams: true,
            path: "auth/forget3",
            name: "Forget3",
            query: [
              {
                name: "email",
                value: this.form.email,
              },
              {
                name: "authCode",
                value: this.form.authCode,
              },
            ],
          });
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
    actionRegister() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/register",
        name: "Register",
        query: [],
      });
    },
    actionSendAuthCode() {
      if (this.sendAuthCodeTime !== 60) {
        return;
      }
      const rule = {
        email: [{ required: true, message: this.$t.errorParamGoBack }],
      };
      const validator = new Schema(rule);
      validator
        .validate(this.form)
        .then((_) => {
          this.$commonMethod.showLoading();
          return this.$api.system.sendEmailCode({
            Email: this.form.email,
          });
        })
        .then((_) => {
          this.$commonMethod.hideLoading();
          const originTime = this.sendAuthCodeTime;
          this.timer = setInterval(() => {
            this.sendAuthCodeTime--;
            if (this.sendAuthCodeTime === 0) {
              this.sendAuthCodeTime = originTime;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
.forget-2 {
  padding: 2rem 1rem;
  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .card {
    margin-top: 15px;
  }

  .btns {
    margin: 43px 25px 60px;

    & > .btn {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $-color-background;
      border-radius: 25px;
      font-size: 15px;
      margin-bottom: 9px;
      border: 0.5px solid $-color-background;

      &.primary {
        background-color: transparent;
        color: white;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-submit {
    height: 50px;
    margin: 35px 25px 0;
    background-color: $-color-background;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 25px;
    font-size: 15px;
  }
  .bottom-action {
    font-size: 14px;
    color: $-color-background;
    text-align: center;
    position: absolute;
    bottom: 25px;
    width: 100%;
    margin: 0 -15px;
  }
  .send-auth-code-btn {
    font-size: 13px;
    color: $-color-light-gray;
    &.active {
      color: $-color-primary;
    }
  }
}
</style>
